import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"

export default function ExecomList({ title, data }) {
  return (
    <>
      {data?.length > 0 ? (
        <div className="container mx-auto my-4">
          <div className="heading">
            {title}
            <div className="underline"></div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xxl-5 justify-content-center">
            {data.map(el => (
              <div
                className="col d-flex flex-column align-items-center my-3" //my-4 -> my-3
                key={el.id}
              >
                <GatsbyImage
                  className="rounded m-2"
                  image={getImage(el.image)}
                  alt={el.name + " - IEEE Student Branch College of Engineering Vadakara"} //Added the SB Name along with name on 'alt' for SEO purpose
                />
                {/* Added a Margin Bottom mb-0 for design purpose*/}
                <p className="fs-5 fw-bold mb-0">{el.name}</p> 
                <p className="text-center">{el.position}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

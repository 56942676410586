import { graphql } from "gatsby"
import Layout from "../../layouts/MainLayout"
import ExecomList from "../../components/ExecomList"
import React from "react"

export default function Execom({ data }) {
  const execom = data.execomJson
  return (
    <Layout>
      <div className="banner">Execom {execom.year}</div>
      <ExecomList title="SB Execom" data={execom.core} />
      <ExecomList title="CS Execom" data={execom.cs} />
      <ExecomList title="PES Execom" data={execom.pes} />
      <ExecomList title="Wie Execom" data={execom.wie} />
      <ExecomList title="Edsoc Execom" data={execom.edsoc} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    execomJson(id: { eq: $id }) {
      core {
        id
        image {
          childImageSharp {
            gatsbyImageData(height: 180)
          }
        }
        name
        position
      }
      cs {
        id
        name
        position
        image {
          childImageSharp {
            gatsbyImageData(height: 180)
          }
        }
      }
      pes {
        position
        name
        id
        image {
          childImageSharp {
            gatsbyImageData(height: 180)
          }
        }
      }

      wie {
        position
        name
        id
        image {
          childImageSharp {
            gatsbyImageData(height: 180)
          }
        }
      }

      edsoc {
        position
        name
        id
        image {
          childImageSharp {
            gatsbyImageData(height: 180)
          }
        }
      }
      year
    }
  }
`
